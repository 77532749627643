export enum STORAGE_KEY {
  ACCESS_TOKEN = 'access-token',
  REFRESH_TOKEN = 'refresh-token',
  ACCOUNT_INFO = 'account-info',
  BUILDING_ID = 'building-id',
  NOTIFY_TOKEN = 'notify-token',
  INCURRED_EXPENSES = 'incurred-expenses',
  APP_CONFIG = 'app-config',
  BRANCH_ID ='branch-id',
}
