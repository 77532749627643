/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ComponentType, useEffect, useState } from 'react';
import CustomFormLabel from 'components/theme-elements/CustomFormLabel';
import { DATE_FORMAT } from 'constants/common';
import { useTranslation } from 'react-i18next';
import { Box, FormHelperText, TextField, TextFieldProps } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TablerIconsProps } from '@tabler/icons-react';
import moment from 'moment';
import 'moment/locale/vi';

moment.locale('vi');

type Props = {
  isRequired?: boolean;
  Icon?: ComponentType<TablerIconsProps>;
  onChange?: (newValue: string) => void;
  errorMessage?: string;
  value?: string;
  title?: string;
  placeholder?: string;
  isDisable?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  disablePast?: boolean;
};

const DateInput = ({
  isRequired = false,
  isDisable = false,
  onChange,
  errorMessage = '',
  value = '',
  title = '',
  minDate,
  maxDate,
  disablePast = false,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [date, setDate] = useState('');

  const handleChange = (value: any) => {
    const newDate = moment(value, 'MM-DD-YYYY').format(DATE_FORMAT);
    onChange && onChange(newDate);
  };

  useEffect(() => {
    setDate(moment(value, DATE_FORMAT).format('YYYY-MM-DD'));
  }, [value]);

  return (
    <Box width="100%">
      {title && (
        <CustomFormLabel className={`${isRequired ? 'required' : ''}`} sx={{ mt: '0px' }}>
          {t(title)}
        </CustomFormLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n?.language}>
        <DesktopDatePicker
          value={date}
          InputProps={{
            autoComplete: 'off',
          }}
          disabled={isDisable}
          inputFormat={DATE_FORMAT}
          onChange={handleChange}
          renderInput={(param: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField
              {...param}
              size="small"
              error={Boolean(errorMessage)}
              disabled={isDisable}
              inputProps={{ ...param.inputProps, placeholder: DATE_FORMAT, readOnly: false }}
              sx={{
                width: '100%',
                '.MuiInputBase-root': { padding: '5px', paddingRight: '14px' },
                '.MuiInputBase-input': {
                  padding: '7.5px 4px 7.5px 6px',
                },
              }}
            />

            // minDate={minDate}
            // maxDate={maxDate}
            // disablePast={disablePast}
          )}
        />
      </LocalizationProvider>
      {errorMessage && (
        <FormHelperText error>{t(`validate-message.${errorMessage}`)}</FormHelperText>
      )}
    </Box>
  );
};

export default React.memo(DateInput);
