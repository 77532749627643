import { createBooking, updateBooking, updateCheckoutBooking } from 'services/user/booking.service';
import {
  assignUserToRoom,
  deleteUser,
  getListUser,
  getListUserInBuilding,
  getOverviewInvoiceByClient,
  getUserDetail,
  unassignUserToRoom,
  updateUser,
} from 'services/user/user.service';
import { BookingReq } from 'types/booking.type';
import { CommonReq, PagingReq } from 'types/common.type';
import { AssignUser, UserReq, UserType } from 'types/user.type';
import { isSuccessCode } from 'utils/common';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getListUserPagingAction = createAsyncThunk(
  'user/getListUserAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListUser(payload);

    return response.data;
  },
);

export const getListUserInBuildingAction = createAsyncThunk(
  'user/getListUserInBuildingAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListUserInBuilding(payload);

    return response.data;
  },
);

export const updateUserAction = createAsyncThunk(
  'user/updateUserAction',
  async (payload: CommonReq & UserType) => {
    const response = await updateUser(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const getUserDetailAction = createAsyncThunk(
  'user/getUserDetailAction',
  async (payload: UserReq) => {
    const response = await getUserDetail(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback(response.data.data);
    }

    return response.data;
  },
);

export const createBookingAction = createAsyncThunk(
  'user/createBookingAction',
  async (payload: CommonReq & BookingReq) => {
    const response = await createBooking(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const updateBookingAction = createAsyncThunk(
  'user/updateBookingAction',
  async (payload: CommonReq & BookingReq) => {
    const response = await updateBooking(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const updateCheckoutBookingAction = createAsyncThunk(
  'user/updateCheckoutBookingAction',
  async (payload: CommonReq) => {
    const response = await updateCheckoutBooking(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const assignUserToRoomAction = createAsyncThunk(
  'user/assignUserToRoomAction',
  async (payload: CommonReq & AssignUser) => {
    const response = await assignUserToRoom(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);
export const unassignUserToRoomAction = createAsyncThunk(
  'user/unassignUserToRoomAction',
  async (payload: CommonReq) => {
    const response = await unassignUserToRoom(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const getOverviewInvoiceByClientAction = createAsyncThunk(
  'user/getOverviewInvoiceByClientAction',
  async (payload: CommonReq) => {
    const response = await getOverviewInvoiceByClient(payload);

    return response.data;
  },
);

export const deleteUserAction = createAsyncThunk(
  'user/deleteUserAction',
  async (payload: CommonReq & UserReq) => {
    const response = await deleteUser(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);
