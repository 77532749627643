import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { PropertyState } from '../reducer';
import { getBranchAction, getBranchDetailAction, getBranchOverviewAction } from '../actions';
import { CustomResponseType } from 'types/common.type';
import { isSuccessCode } from 'utils/common';
import { LocalStorage } from 'utils/localStorage';
import { STORAGE_KEY } from 'enums/localStorage';

const Storage = new LocalStorage();

export const branchReducer = {
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<PropertyState>) => {
    builder.addCase(
      getBranchAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listBranch = action.payload.data;

          const storedBranchId = Storage.getStorageItem(STORAGE_KEY.BRANCH_ID);
          const branchId = storedBranchId || action.payload.data?.[0]?.id;

          if (branchId) {
            state.branchDetail = action.payload.data[0];
            Storage.setStorageItem(STORAGE_KEY.BRANCH_ID, branchId);
          }
        }
      },
    );

    builder.addCase(
      getBranchDetailAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.branchDetail = action.payload.data;
        }
      },
    );

    builder.addCase(
      getBranchOverviewAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.branchOverview = action.payload.data;
        }
      },
    );
  },
};
