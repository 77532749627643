import * as React from 'react';
import { DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogWrapper from 'components/dialog/DialogWrapper';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
};

function ConfirmModal({ isOpen = false, onClose, onSubmit }: Props) {
  const { t } = useTranslation();

  const handleSubmit = () => {
    onSubmit && onSubmit();
    onClose && onClose();
  };

  return (
    <DialogWrapper
      title="confirm"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      isFullWidth={false}
    >
      <DialogContentText id="alert-dialog-description">{t('common-message.ms4')}</DialogContentText>
    </DialogWrapper>
  );
}

export default React.memo(ConfirmModal);
