export enum PDFFontWeight {
  REGULAR = 'regular',
  MEDIUM = 'medium',
  BOLD = 'bold',
}

export type DrawPDFConfigType = {
  dataKey: string;
  pageIdx: number;
  x: number;
  y: number;
  fontSize: number;
  r: number;
  g: number;
  b: number;
  fontWeight: 'regular' | 'medium' | 'bold';
  isCenter?: boolean;
};

export type FormContractType = {
  id: number;
  name: string;
  buildingId: number;
  pdfLink: string;
  extraData: string;
  isForEachPerson: boolean;
  isActive: boolean;
};

export type TemplateType = {
  name: string;
  pdfLink: string;
  extraData: string;
};

export type CreatePDFContractReq = {
  pdfLink: string;
  name: string;
  extraData: any;
};
