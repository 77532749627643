import { createSlice } from '@reduxjs/toolkit';
import { getAccessLogsAction } from './actions';
import { AccessLog } from 'types/access-control.type';

interface AccessControlState {
  accessLogs: {
    total: number;
    data: AccessLog[];
  };
}

const initialState: AccessControlState = {
  accessLogs: {
    total: 0,
    data: [],
  },
};

const accessControlSlice = createSlice({
  name: 'accessControl',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccessLogsAction.fulfilled, (state, action) => {
      state.accessLogs = {
        total: action.payload.data.total,
        data: action.payload.data.data,
      };
    });
  },
});

export const {} = accessControlSlice.actions;
export default accessControlSlice.reducer;
