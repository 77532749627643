import { isEmpty } from 'lodash';
import { centralGW } from 'services/axios.service';
import { BookingReq } from 'types/booking.type';
import { CommonReq, PagingReq } from 'types/common.type';

export const getListBooking = (payload: CommonReq & PagingReq) => {
  const { userId, page, limit } = payload;

  return centralGW.get(`/api/v1/user/${userId}/booking`, {
    params: {
      page,
      limit,
    },
    isDisableToast: true,
  });
};

export const getListBookingInBuilding = (payload: CommonReq & PagingReq) => {
  const { buildingId, page, limit, q, roomId, floorId, status, isFull } = payload;

  return centralGW.get(`/api/v1/user/building/${buildingId}/booking`, {
    params: {
      page,
      limit,
      isFull,
      ...(q ? { q } : {}),
      ...(roomId ? { roomId } : {}),
      ...(floorId ? { floorId } : {}),
      ...(status ? { status } : {}),
    },
    isDisableToast: true,
  });
};

export const getOptionBookingInBuilding = (payload: CommonReq) => {
  const { buildingId, isPaid } = payload;

  return centralGW.get(`/api/v1/user/building/${buildingId}/booking`, {
    params: {
      isPaid,
      isFull: true,
    },
    isDisableToast: true,
    isDisableLoading: true,
  });
};

export const getListBookingInRoom = (payload: CommonReq & PagingReq) => {
  const { roomId, page, limit } = payload;

  return centralGW.get(`/api/v1/user/room/${roomId}/booking`, {
    params: {
      page,
      limit,
    },
    isDisableToast: true,
  });
};

export const createBooking = (payload: CommonReq & BookingReq) => {
  const { userId, roomId, depositPrice, checkInAt } = payload;

  return centralGW.post(`/api/v1/user/${userId}/room/${roomId}/booking`, {
    depositPrice,
    checkInAt,
  });
};

export const updateBooking = (payload: CommonReq & BookingReq) => {
  const { checkInAt, checkOutAt, bookingId, totalPrice } = payload;

  return centralGW.put(`/api/v1/user/booking/${bookingId}`, {
    ...(checkInAt ? { checkInAt } : {}),
    ...(checkOutAt ? { checkOutAt } : {}),
    ...(!isEmpty(String(totalPrice)) ? { totalPrice } : {}),
  });
};

export const getCurrentBookingInRoom = (payload: CommonReq) => {
  const { roomId } = payload;

  return centralGW.get(`/api/v1/user/room/${roomId}/booking/current`, {
    isDisableToast: true,
  });
};

export const updateCheckoutBooking = (payload: CommonReq) => {
  const { bookingId } = payload;

  return centralGW.put(`/api/v1/user/booking/${bookingId}/checkout`);
};

export const cancelBooking = (payload: CommonReq) => {
  const { bookingId } = payload;

  return centralGW.put(`/api/v1/user/booking/${bookingId}/cancel`);
};

export const getDepositInvoiceBooking = (payload: CommonReq) => {
  const { bookingId } = payload;

  return centralGW.get(`/api/v1/user/booking/${bookingId}/deposit-invoice`, {
    isDisableToast: true,
  });
};
