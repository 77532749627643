import { centralGW } from 'services/axios.service';
import { CheckoutReq } from 'types/checkout.type';
import { CommonReq, PagingReq } from 'types/common.type';
import { ContractReq, RenewalContractType } from 'types/contract.type';

export const getListContract = (payload: CommonReq & PagingReq) => {
  const { userId, page, limit } = payload;

  return centralGW.get(`/api/v1/user/${userId}/contract`, {
    params: {
      page,
      limit,
    },
    isDisableToast: true,
  });
};

export const getListContractInRoom = (payload: CommonReq & PagingReq) => {
  const { roomId, isFull } = payload;

  return centralGW.get(`/api/v1/user/room/${roomId}/contract`, {
    params: {
      isFull,
    },
    isDisableToast: true,
  });
};

export const getContractDetail = (payload: CommonReq) => {
  const { contractId } = payload;

  return centralGW.get(`/api/v1/user/contract/${contractId}/detail`, {
    isDisableToast: true,
  });
};

export const createContract = (payload: CommonReq & ContractReq) => {
  const {
    userId,
    roomId,
    startAt,
    endAt,
    note,
    bookingId,
    numberOfPeople,
    price,
    discount = 0,
  } = payload;

  return centralGW.post(`/api/v1/user/${userId}/room/${roomId}/contract`, {
    startAt,
    endAt,
    note,
    bookingId,
    numberOfPeople,
    price,
    discount,
  });
};

export const renewalContract = (payload: CommonReq & RenewalContractType) => {
  const { contractId, signBy, numberOfPeople, price, endAt, startAt, note, discount } = payload;

  return centralGW.post(`/api/v1/user/contract/${contractId}/extend`, {
    signBy,
    ...(numberOfPeople ? { numberOfPeople } : {}),
    ...(price ? { price } : {}),
    ...(endAt ? { endAt } : {}),
    ...(startAt ? { startAt } : {}),
    ...(note ? { note } : {}),
    ...(discount ? { discount } : {}),
  });
};

export const getContractInBuildingPaging = (payload: CommonReq & PagingReq) => {
  const { buildingId, page, limit, floorId, roomId, isExpired, q, isFull } = payload;

  return centralGW.get(`/api/v1/user/building/${buildingId}/contracts`, {
    params: {
      page,
      limit,
      ...(q ? { q } : {}),
      ...(roomId ? { roomId } : {}),
      ...(floorId ? { floorId } : {}),
      isExpired,
      isFull,
    },
    isDisableToast: true,
    isDisableLoading: true,
  });
};

export const changeRepresentativeInRoom = async (payload: CommonReq) => {
  const { userId, contractId } = payload;

  return centralGW.put(`/api/v1/user/contract/${contractId}/change-represent`, {
    userId,
  });
};

export const checkout = (payload: CommonReq & CheckoutReq) => {
  const {
    bookingId,
    paymentMethodId,
    refundAmount,
    debitAmount,
    receivedUserId,
    otherTransactionsWithPaymentMethod,
  } = payload;

  return centralGW.put(`/api/v1/user/booking/${bookingId}/checkout`, {
    paymentMethodId,
    refundAmount,
    debitAmount,
    receivedUserId,
    ...(otherTransactionsWithPaymentMethod ? { otherTransactionsWithPaymentMethod } : {}),
  });
};

export const cancelContract = (payload: CommonReq & CheckoutReq) => {
  const { bookingId, profitAmount, debitAmount, otherTransactions } = payload;

  return centralGW.put(`/api/v1/user/booking/${bookingId}/contract/cancel`, {
    profitAmount,
    debitAmount,
    ...(otherTransactions ? { otherTransactions } : {}),
  });
};

export const updateContractInfo = async (payload: CommonReq & ContractReq) => {
  const { contractId, contractPdfPath } = payload;

  return centralGW.put(`/api/v1/user/contract/${contractId}/update`, {
    contractPdfPath,
  });
};
