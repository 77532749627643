import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import { combineReducers } from 'redux';

import { configureStore } from '@reduxjs/toolkit';

import AuthReducer from './auth/reducer';
import BookingReducer from './booking/reducer';
import ClientReducer from './client/reducer';
import CommonReducer from './common/reducer';
import ContractReducer from './contract/reducer';
import ContractFeeReducer from './contractFee/reducer';
import CustomizerReducer from './customizer/reducer';
import EmployeeReducer from './employee/reducer';
import GeneralReducer from './general/reducer';
import InvoiceReducer from './invoice/reducer';
import kanbanReducer from './kanban/reducer';
import PropertyReducer from './property/reducer';
import RoomReducer from './room/reducer';
import TransactionReducer from './transaction/reducer';
import UserReducer from './user/reducer';
import VehicleReducer from './vehicle/reducer';
import ReportReducer from './report/reducer';
import DashboardReducer from './dashboard/reducer';
import accessControlReducer from './access-control/reducer';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    common: CommonReducer,
    client: ClientReducer,
    auth: AuthReducer,
    property: PropertyReducer,
    general: GeneralReducer,
    user: UserReducer,
    employee: EmployeeReducer,
    booking: BookingReducer,
    contractFee: ContractFeeReducer,
    contract: ContractReducer,
    vehicle: VehicleReducer,
    invoice: InvoiceReducer,
    transaction: TransactionReducer,
    room: RoomReducer,
    kanban: kanbanReducer,
    report: ReportReducer,
    dashboard: DashboardReducer,
    accessControl: accessControlReducer,
  },
});

const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  common: CommonReducer,
  client: ClientReducer,
  auth: AuthReducer,
  property: PropertyReducer,
  general: GeneralReducer,
  user: UserReducer,
  employee: EmployeeReducer,
  booking: BookingReducer,
  contract: ContractReducer,
  vehicle: VehicleReducer,
  invoice: InvoiceReducer,
  transaction: TransactionReducer,
  room: RoomReducer,
  contractFee: ContractFeeReducer,
  kanban: kanbanReducer,
  report: ReportReducer,
  dashboard: DashboardReducer,
  accessControl: accessControlReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const { dispatch } = store;
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;

export default store;
