import Joi from 'joi';

import { REGEX } from './regex';

export const CUSTOM_JOI_MESSAGE = {
  'string.empty': 'required',
};

export const JOI = {
  REQUIRE_ONLY: Joi.string().required(),
  PHONE: Joi.string().regex(REGEX.PHONE).messages({ 'string.pattern.base': 'invalid-phone' }),
  PHONE_REQUIRE: Joi.string()
    .required()
    .regex(REGEX.PHONE)
    .messages({ 'string.pattern.base': 'invalid-phone' }),
  PASSWORD: Joi.string()
    .required()
    .regex(REGEX.PASSWORD)
    .messages({ 'string.pattern.base': 'invalid-password' }),
  REQUIRE: Joi.string().required().max(500).messages({ 'string.max': 'invalid-text' }),
  MAX_LENGTH: Joi.string().required().max(100).messages({ 'string.max': 'invalid-text' }),
  MAX_LENGTH_10: Joi.string()
    .required()
    .max(10)
    .messages({ 'string.max': 'invalid-max-length-10' })
    .messages({ 'any.required': 'required' }),
  REQUIRE_ARRAY: Joi.array().items().required().min(1).messages({ 'array.min': 'min-building' }),
  REQUIRE_MIN_LABEL: Joi.array().items().required().min(1).messages({ 'array.min': 'min-level' }),
  PRICE: Joi.string().required().min(1).messages({ 'string.min': 'invalid-price' }),
  NUMBER: Joi.number().required().min(1).messages({
    'number.min': 'min-number',
    'number.base': 'invalid-number-type',
    'number.unsafe': 'invalid-number',
  }),
  ALLOW: Joi.any(),
  EMAIL: Joi.string()
    .email({
      tlds: {
        allow: false,
      },
    })
    .allow(null, '')
    .message('invalid-email'),
  IMAGE_URL: Joi.array().min(2).required().messages({
    'array.min': 'required',
    'any.required': 'required',
  }),
};
