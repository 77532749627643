import React, { forwardRef, useImperativeHandle, useState } from 'react';

import AutoComplete from 'components/shared/AutoComplete';
import InputNumber from 'components/shared/InputNumber';
import InputPrice from 'components/shared/InputPrice';
import RadioGroup from 'components/shared/RadioGroup';
import SwitchLabel from 'components/shared/SwitchLabel';
import TextInput from 'components/shared/TextInput';
import { JOI } from 'constants/joi';
import { ROOM_PAID_MONTH } from 'enums';
import { FloorRes, RoomTypeRes } from 'types/general.type';
import { RoomType } from 'types/room.type';
import { createSchema, validateSchema } from 'utils/joi';

import { Grid } from '@mui/material';
import {
  IconBrandAppleArcade,
  IconCoin,
  IconDoor,
  IconFrame,
  IconStack2,
  IconUsers,
} from '@tabler/icons-react';
import { PERMISSION } from 'constants/permission';

interface Props {
  data: RoomType;
  isHideFloorField?: boolean;
  listRoomTypeOptions?: RoomTypeRes[];
  listFloorNameOptions?: FloorRes[];
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
  isColumn?: boolean;
  isDisable?: boolean;
  isEdit?: boolean;
  isHideName?: boolean;
}

const initError = {
  name: '',
  roomTypeId: '',
  floorId: '',
  maxUser: '',
  area: '',
  price: '',
};

const RoomForm = forwardRef(
  (
    {
      data,
      listRoomTypeOptions = [],
      listFloorNameOptions = [],
      onChange,
      onSubmit,
      isColumn = false,
      isDisable = false,
      isHideFloorField = false,
      isEdit = false,
      isHideName = false,
    }: Props,
    ref,
  ) => {
    const [roomError, setRoomError] = useState(initError);
    const schema = createSchema({
      name: JOI.REQUIRE,
      price: JOI.PRICE,
      roomTypeId: JOI.REQUIRE,
      floorId: JOI.REQUIRE,
      maxUser: JOI.NUMBER,
      area: JOI.NUMBER,
    });

    const handleChange = (key: string) => (value: string | boolean) => {
      onChange(key, value);
    };

    const handleSubmitRoom = () => {
      const clone = { ...data };
      delete clone.roomStatus;
      delete clone.isPaidStartOfMonth;
      delete clone.isMaintenance;
      const resError = validateSchema(schema, clone);
      setRoomError(resError.errorMessage);
      if (!resError.isError) {
        onSubmit && onSubmit();
      }
    };

    useImperativeHandle(ref, () => ({
      handleSubmitRoom,
    }));

    return (
      <Grid container spacing="12px">
        {!isHideName && (
          <Grid item xs={12} lg={isColumn ? 12 : 6}>
            <TextInput
              isRequired
              title="room-name"
              Icon={IconDoor}
              value={data.name}
              placeholder="placeholder.room-name"
              onChange={handleChange('name')}
              errorMessage={roomError.name}
              autoFocus={true}
              isDisable={isDisable}
            />
          </Grid>
        )}
        <Grid container item spacing="12px">
          <Grid item xs={12} lg={isColumn ? 12 : 6}>
            <RadioGroup
              isDisable={isDisable}
              title="time-to-payment"
              value={Number(data.isPaidStartOfMonth)}
              onChange={handleChange('isPaidStartOfMonth')}
              options={[
                {
                  value: ROOM_PAID_MONTH.PREV,
                  label: 'start-month',
                  permission: [PERMISSION.CREATE_ROOM],
                },
                {
                  value: ROOM_PAID_MONTH.LAST,
                  label: 'end-month',
                  permission: [PERMISSION.CREATE_ROOM],
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={isColumn ? 12 : 6}>
            <AutoComplete
              isRequired
              title="room-type"
              options={listRoomTypeOptions.map((roomType) => ({
                label: roomType.name || '',
                value: String(roomType.id),
              }))}
              value={data.roomTypeId}
              placeHolder="placeholder.choose-room-type"
              errorMessage={roomError.roomTypeId}
              onChange={handleChange('roomTypeId')}
              Icon={IconBrandAppleArcade}
              isDisable={isDisable}
            />
          </Grid>
          {!isHideFloorField && (
            <Grid item xs={12} lg={isColumn ? 12 : 6}>
              <AutoComplete
                isRequired
                title="floor"
                options={listFloorNameOptions.map((floor) => ({
                  label: floor.name || '',
                  value: String(floor.id),
                }))}
                value={data.floorId}
                placeHolder="placeholder.choose-floor"
                errorMessage={roomError.floorId}
                onChange={handleChange('floorId')}
                Icon={IconStack2}
                isDisable={isDisable}
              />
            </Grid>
          )}
        </Grid>
        <Grid container item spacing="12px">
          <Grid item xs={12} lg={isColumn ? 12 : 4}>
            <InputNumber
              isRequired
              Icon={IconFrame}
              title="acreage"
              value={data.area}
              onChange={handleChange('area')}
              errorMessage={roomError.area}
              placeholder={'32m2...'}
              isDisable={isDisable}
            />
          </Grid>
          <Grid item xs={12} lg={isColumn ? 12 : 4}>
            <InputNumber
              isRequired
              onChange={handleChange('maxUser')}
              value={data.maxUser}
              title={'capacity'}
              placeholder={'placeholder.capacity'}
              errorMessage={roomError.maxUser}
              Icon={IconUsers}
              isDisable={isDisable}
            />
          </Grid>
          <Grid item xs={12} lg={isColumn ? 12 : 4}>
            <InputPrice
              isRequired
              onChange={handleChange('price')}
              value={data.price}
              title={'price'}
              placeholder={'3.200.000 VNĐ...'}
              errorMessage={roomError.price}
              Icon={IconCoin}
              isDisable={isDisable}
            />
          </Grid>
          <Grid item xs={12}>
            <SwitchLabel
              title="room-status"
              isChecked={Boolean(data.roomStatus)}
              onChange={handleChange('roomStatus')}
              isDisable={isDisable}
            />
          </Grid>
        </Grid>
        {isEdit && (
          <Grid item xs={12}>
            <SwitchLabel
              title="maintenance"
              isChecked={Boolean(data.isMaintenance)}
              onChange={handleChange('isMaintenance')}
              isDisable={isDisable}
            />
          </Grid>
        )}
      </Grid>
    );
  },
);

export default React.memo(RoomForm);
