import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

import { DrawPDFConfigType, PDFFontWeight } from 'types/pdf';
import { useState } from 'react';

export default function useFillPdf() {
  const [pdfFile, setPdfFile] = useState<Blob | null>(null);

  const drawDataToPDF = async (
    drawConfigs: DrawPDFConfigType[],
    pdfFilePath: string,
    data: any,
  ) => {
    const existingPdfBytes = await fetch(pdfFilePath).then((res) => res.arrayBuffer());
    const [fontByteRegular, fontByteMedium, fontByteBold] = await Promise.all([
      fetch('/Roboto-Regular.ttf').then((res) => res.arrayBuffer()),
      fetch('/Roboto-Medium.ttf').then((res) => res.arrayBuffer()),
      fetch('/Roboto-Bold.ttf').then((res) => res.arrayBuffer()),
    ]);

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();

    pdfDoc.registerFontkit(fontkit);
    const [fontRegular, fontMedium, fontBold] = await Promise.all([
      pdfDoc.embedFont(fontByteRegular),
      pdfDoc.embedFont(fontByteMedium),
      pdfDoc.embedFont(fontByteBold),
    ]);

    const font = {
      [PDFFontWeight.REGULAR]: fontRegular,
      [PDFFontWeight.MEDIUM]: fontMedium,
      [PDFFontWeight.BOLD]: fontBold,
    };

    drawConfigs.forEach((config) => {
      const drawPage = pages[config.pageIdx];
      const { width } = drawPage.getSize();
      const customFont = font[config.fontWeight];
      const textWidth = customFont.widthOfTextAtSize(data[config.dataKey], config.fontSize);

      const x = config.isCenter ? (width - textWidth) / 2 : config.x;
      drawPage.drawText(data[config?.dataKey] || '', {
        x: x,
        y: drawPage.getHeight() - config.y - config.fontSize + config.fontSize * 0.3,
        size: config.fontSize,
        font: customFont,
        color: rgb(config.r, config.g, config.b),
      });
    });

    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(blob);

    setPdfFile(blob);

    // Lưu URL vào state
    return pdfUrl;
  };

  return { drawDataToPDF, pdfFile };
}
