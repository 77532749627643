export const PERMISSION = {
  F_SETTING: 'F_SETTING',
  DASHBOARD: '',
  P_REFUND_INVOICE: 'P_REFUND_INVOICE',
  G_GET_ROLES: 'G_GET_ROLES',

  SB_DASHBOARD: 'SB_DASHBOARD',
  SB_ROOM: 'SB_ROOM',
  SB_TAB_ROOM_LIST: 'SB_TAB_ROOM_LIST',
  SB_TAB_BOOKING_ROOM: 'SB_TAB_BOOKING_ROOM',
  SB_CONTRACT: 'SB_CONTRACT',
  SB_TAB_CONTRACT_LIST: 'SB_TAB_CONTRACT_LIST',
  SB_TAB_CONTRACT_FORM: 'SB_TAB_CONTRACT_FORM',
  SB_INVOICE: 'SB_INVOICE',
  SB_REPORT: 'SB_REPORT',
  SB_TAB_APPROACHING_EXPIRED: 'SB_TAB_APPROACHING_EXPIRED',
  SB_TAB_APPROACHING_CHECK_IN: 'SB_TAB_APPROACHING_CHECK_IN',
  SB_TAB_TRANSACTION: 'SB_TAB_TRANSACTION',
  SB_CLIENT: 'SB_CLIENT',
  SB_EMPLOYEE: 'SB_EMPLOYEE',
  SB_MAINTENANCE: 'SB_MAINTENANCE',
  SB_MANAGEMENT: 'SB_MANAGEMENT',
  SB_INCOME_TRANSACTION_MODAL: 'SB_INCOME_TRANSACTION_MODAL',
  SB_EXPENSE_TRANSACTION_MODAL: 'SB_EXPENSE_TRANSACTION_MODAL',

  GET_ROOM_DETAIL: 'GET_ROOM_DETAIL',
  EXTEND_CONTRACT: 'EXTEND_CONTRACT',
  CHECK_OUT_BOOKING: 'CHECK_OUT_BOOKING',
  CANCEL_BOOKING: 'CANCEL_BOOKING',
  CHANGE_REPRESENT: 'CHANGE_REPRESENT',
  ASSIGN_USER_TO_ROOM: 'ASSIGN_USER_TO_ROOM',
  CHECK_IN: 'CHECK_IN',
  GET_ROOM_OVERVIEW: 'GET_ROOM_OVERVIEW',
  GET_BOOKINGS_IN_ROOM: 'GET_BOOKINGS_IN_ROOM',
  CREATE_INVOICE: 'CREATE_INVOICE',

  CREATE_BOOKING_IN_ROOM_DETAIL: 'CREATE_BOOKING_IN_ROOM_DETAIL',
  CREATE_BOOKING_IN_ROOM_LIST: 'CREATE_BOOKING_IN_ROOM_LIST',
  CREATE_BOOKING_IN_ROOM_BOOKING: 'CREATE_BOOKING_IN_ROOM_BOOKING',

  UPDATE_BOOKING: 'UPDATE_BOOKING',
  CANCEL_CHECK_IN: 'CANCEL_CHECK_IN',
  GET_CONTRACT_DETAIL: 'GET_CONTRACT_DETAIL',
  GET_USER_DETAIL: 'GET_USER_DETAIL',
  GET_OVERVIEW_INVOICES: 'GET_OVERVIEW_INVOICES',
  CREATE_TEMPLATE_INVOICES: 'CREATE_TEMPLATE_INVOICES',
  GET_TEMPLATE_INVOICES: 'GET_TEMPLATE_INVOICES',
  CONFIRM_TEMPLATE_INVOICES: 'CONFIRM_TEMPLATE_INVOICES',
  SAVE_TEMPLATE_INVOICES: 'SAVE_TEMPLATE_INVOICES',
  PAYMENT_INVOICE: 'PAYMENT_INVOICE',
  CANCEL_INVOICE: 'CANCEL_INVOICE',
  INVOICE_DETAIL: 'INVOICE_DETAIL',
  GET_OVERVIEW_TRANSACTIONS: 'GET_OVERVIEW_TRANSACTIONS',
  SUB_TAB_INVOICE_TRANSACTION: 'SUB_TAB_INVOICE_TRANSACTION',
  SUB_TAB_DEPOSIT_TRANSACTION: 'SUB_TAB_DEPOSIT_TRANSACTION',
  SUB_TAB_OTHER_TRANSACTION: 'SUB_TAB_OTHER_TRANSACTION',
  SUB_TAB_DEBT_TRANSACTION: 'SUB_TAB_DEBT_TRANSACTION',
  REGISTER_USER: 'REGISTER_USER',
  UPDATE_USER: 'UPDATE_USER',
  DELETE_USER: 'DELETE_USER',
  GET_OVERVIEW_WITH_USER: 'GET_OVERVIEW_WITH_USER',
  SUB_TAB_BOOKINGS_WITH_USER: 'SUB_TAB_BOOKINGS_WITH_USER',
  SUB_TAB_CONTRACTS_WITH_USER: 'SUB_TAB_CONTRACTS_WITH_USER',
  SUB_TAB_INVOICES_WITH_USER: 'SUB_TAB_INVOICES_WITH_USER',
  SUB_TAB_TRANSACTIONS_WITH_USER: 'SUB_TAB_TRANSACTIONS_WITH_USER',
  SUB_TAB_VEHICLES_WITH_USER: 'SUB_TAB_VEHICLES_WITH_USER',
  UPLOAD_ID_CARD: 'UPLOAD_ID_CARD',
  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  UPLOAD_IMAGE_EMPLOYEE: 'UPLOAD_IMAGE_EMPLOYEE',
  UPLOAD_ID_CARD_EMPLOYEE: 'UPLOAD_ID_CARD_EMPLOYEE',
  REGISTER_EMPLOYEE: 'REGISTER_EMPLOYEE',
  UPDATE_EMPLOYEE: 'UPDATE_EMPLOYEE',
  UNASSIGN_EMPLOYEE: 'UNASSIGN_EMPLOYEE',
  UN_ASSIGN_USER_FROM_ROOM: 'UN_ASSIGN_USER_FROM_ROOM',
  GET_EMPLOYEE_DETAIL: 'GET_EMPLOYEE_DETAIL',
  ASSIGN_EMPLOYEE: 'ASSIGN_EMPLOYEE',
  UPDATE_BUILDING: 'UPDATE_BUILDING',
  SUB_TAB_ROOMS_FLOORS: 'SUB_TAB_ROOMS_FLOORS',
  SUB_TAB_ROOM_TYPES: 'SUB_TAB_ROOM_TYPES',
  SUB_TAB_SERVICE_FEE_TYPES: 'SUB_TAB_SERVICE_FEE_TYPES',
  SUB_TAB_TRANSACTION_TYPE: 'SUB_TAB_TRANSACTION_TYPE',
  SUB_TAB_PAYMENT_METHOD: 'SUB_TAB_PAYMENT_METHOD',
  DELETE_TRANSACTION: 'DELETE_TRANSACTION',

  SUB_TAB_INVOICE_IN_ROOM: 'SUB_TAB_INVOICE_IN_ROOM',
  SUB_TAB_TRANSACTION_IN_ROOM: 'SUB_TAB_TRANSACTION_IN_ROOM',
  SUB_TAB_SERVICE_IN_ROOM: 'SUB_TAB_SERVICE_IN_ROOM',

  BUILDING_DETAIL: 'BUILDING_DETAIL',
  CREATE_BUILDING: 'CREATE_BUILDING',
  GET_EMPLOYEE_LIST_IN_BUILDING: 'GET_EMPLOYEE_LIST_IN_BUILDING',
  GET_EMPLOYEE_LIST_IN_BRANCH: 'GET_EMPLOYEE_LIST_IN_BRANCH',

  GET_BOOKING_ROOM_LIST: 'GET_BOOKING_ROOM_LIST',

  GET_CLIENT_LIST: 'GET_CLIENT_LIST',

  GET_INVOICE_LIST: 'GET_INVOICE_LIST',

  CREATE_VEHICLE: 'CREATE_VEHICLE',
  UPDATE_VEHICLE: 'UPDATE_VEHICLE',
  DELETE_VEHICLE: 'DELETE_VEHICLE',

  CREATE_FLOOR: 'CREATE_FLOOR',
  UPDATE_FLOOR: 'UPDATE_FLOOR',
  DELETE_FLOOR: 'DELETE_FLOOR',

  CREATE_ROOM: 'CREATE_ROOM',
  UPDATE_ROOM: 'UPDATE_ROOM',
  DELETE_ROOM: 'DELETE_ROOM',

  CREATE_ROOM_TYPE: 'CREATE_ROOM_TYPE',
  UPDATE_ROOM_TYPE: 'UPDATE_ROOM_TYPE',
  DELETE_ROOM_TYPE: 'DELETE_ROOM_TYPE',

  CREATE_SERVICE_FEE_TYPE: 'CREATE_SERVICE_FEE_TYPE',
  UPDATE_SERVICE_FEE_TYPE: 'UPDATE_SERVICE_FEE_TYPE',
  DELETE_SERVICE_FEE_TYPE: 'DELETE_SERVICE_FEE_TYPE',

  CREATE_TRANSACTION_TYPE: 'CREATE_TRANSACTION_TYPE',
  DELETE_TRANSACTION_TYPE: 'DELETE_TRANSACTION_TYPE',
  UPDATE_TRANSACTION_TYPE: 'UPDATE_TRANSACTION_TYPE',

  UPDATE_PAYMENT_METHOD: 'UPDATE_PAYMENT_METHOD',
  GET_HISTORY_TAB: 'GET_HISTORY_TAB',
  GET_COMMENT_TAB: 'GET_COMMENT_TAB',
  CREATE_COMMENT: 'CREATE_COMMENT',

  MOVE_TAB: 'MOVE_TAB',
  CREATE_TAB: 'CREATE_TAB',
  DELETE_TAB: 'DELETE_TAB',
  UPDATE_TAB: 'UPDATE_TAB',

  MOVE_TASK: 'MOVE_TASK',
  UPDATE_TASK_INFO: 'UPDATE_TASK_INFO',
  CREATE_TASK: 'CREATE_TASK',
  DELETE_TASK: 'DELETE_TASK',

  CREATE_CONTRACT_FEE: 'CREATE_CONTRACT_FEE',
  UPDATE_CONTRACT_FEE: 'UPDATE_CONTRACT_FEE',
  DELETE_CONTRACT_FEE: 'DELETE_CONTRACT_FEE',

  REFUND_DEPOSIT_TRANSACTION: 'REFUND_DEPOSIT_TRANSACTION',
  REFUND_INVOICE_TRANSACTION: 'REFUND_INVOICE_TRANSACTION',
  OTHER_EXPENSE_TRANSACTION: 'OTHER_EXPENSE_TRANSACTION',

  CREATE_DEPOSIT_TRANSACTION: 'CREATE_DEPOSIT_TRANSACTION',
  CREATE_OTHER_TRANSACTION: 'CREATE_OTHER_TRANSACTION',
  CREATE_INVOICE_TRANSACTION: 'CREATE_INVOICE_TRANSACTION',

  DELETE_DEPOSIT_TRANSACTION: 'DELETE_DEPOSIT_TRANSACTION',

  CONFIRM_CONTRACT_PDF: 'CONFIRM_CONTRACT_PDF',
};
