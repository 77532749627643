import { centralGW } from 'services/axios.service';
import { CommonReq } from 'types/common.type';
import { getBranchId } from 'utils/common';

export const getAllBranches = async (payload: CommonReq) => {
  const { isActive } = payload;

  return centralGW.get(`/api/v1/property/branch`, {
    params: { isActive },
    isDisableToast: true,
  });
};

export const getBranchDetail = async () => {
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/property/branch/${branchId}/detail`, {
    isDisableToast: true,
  });
};

export const getBranchOverview = async () => {
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/property/branch/${branchId}/overview`, { isDisableToast: true });
};

export const getBranchConfig = async (domain: string) => {
  return centralGW.get('/api/v1/property/branch-detail', {
    params: {
      crmDomain: domain,
    },
    isDisableLoading: true,
    isDisableToast: true,
  });
};
