import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAccessLogs } from 'services/access-control.service';
import { CommonReq, PagingReq } from 'types/common.type';
import { ReportReq } from 'types/report.type';

export const getAccessLogsAction = createAsyncThunk(
  'access-control/getAccessLogs',
  async (payload: CommonReq & PagingReq & ReportReq) => {
    const response = await getAccessLogs(payload);

    return response.data;
  },
);
