import * as React from 'react';

import Scrollbar from 'components/custom-scroll/Scrollbar';
import BlankCard from 'components/shared/BlankCard';
import CopyOnHover from 'components/shared/CopyOnHover';
import { DEFAULT_LIMIT, ROW_PER_PAGE_OPTIONS } from 'constants/common';
import { useTranslation } from 'react-i18next';
import { showData } from 'utils/common';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';

type Props = {
  header?: any[];
  data?: any[];
  cols?: any[][];
  total?: number;
  limit?: number;
  currentPage?: number;
  isAction?: boolean;
  hidePagination?: boolean;
  onChangePage?: (newPage: number) => void;
  onChangeLimit?: (newPage: number) => void;
};

function TableNormal({
  header = [],
  data = [],
  total = 0,
  currentPage = 1,
  limit = DEFAULT_LIMIT,
  isAction = false,
  hidePagination = false,
  onChangePage,
  onChangeLimit,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    onChangePage && onChangePage(page);
  };

  const handleChangeLimit = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChangeLimit && onChangeLimit(Number(event.target.value));
  };

  return (
    <BlankCard>
      <TableContainer component={Paper}>
        <Scrollbar>
          <Table
            sx={{
              minWidth: 750,
              whiteSpace: {
                xs: 'nowrap',
                sm: 'unset',
              },
            }}
            size="medium"
          >
            <TableHead>
              <TableRow>
                {header.map((item, cIdx) => (
                  <TableCell
                    key={cIdx}
                    sx={{
                      textAlign: cIdx === 0 ? 'left' : 'center',
                      position: isAction && cIdx === header.length - 1 ? 'sticky' : 'initial',
                      right: isAction && cIdx === header.length - 1 ? 0 : 'auto',
                      backgroundColor:
                        isAction && cIdx === header.length - 1
                          ? 'var(--header-table-color)'
                          : 'inherit',
                      boxShadow:
                        isAction && cIdx === header.length - 1
                          ? `2px 0 0 ${theme.palette.divider} inset`
                          : '',
                      zIndex: isAction && cIdx === header.length - 1 ? 1 : 'auto',
                    }}
                  >
                    {t(item)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {data && data.length > 0 && (
              <>
                <TableBody>
                  {data.map((row: any, rIdx: number) => (
                    <TableRow key={rIdx}>
                      {row.map((col: any, cIdx: number) => (
                        <TableCell
                          key={`${rIdx}${cIdx}`}
                          sx={{
                            minWidth: '150px',
                            fontSize: '14px',
                            textAlign: cIdx === 0 ? 'left' : 'center',
                            position: isAction && cIdx === row.length - 1 ? 'sticky' : 'initial',
                            right: isAction && cIdx === row.length - 1 ? 0 : 'auto',
                            boxShadow:
                              isAction && cIdx === row.length - 1
                                ? `2px 0 0 ${theme.palette.divider} inset`
                                : '',
                            backgroundColor:
                              isAction && cIdx === row.length - 1 ? 'white' : 'inherit',
                            zIndex: isAction && cIdx === row.length - 1 ? 1 : 'auto',
                          }}
                        >
                          <CopyOnHover text={showData(col)} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </Scrollbar>
        {!data.length && (
          <Box width="100%" display="flex" justifyContent="center" p={5}>
            <Typography>{t('no-data')}</Typography>
          </Box>
        )}
        {!hidePagination && (
          <TablePagination
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
            rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
            colSpan={header.length}
            count={total}
            rowsPerPage={limit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeLimit}
            ActionsComponent={TablePaginationActions}
          />
        )}
      </TableContainer>
    </BlankCard>
  );
}

export default React.memo(TableNormal);
