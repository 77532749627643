import { centralGW } from 'services/axios.service';
import { CommonReq, PagingReq } from 'types/common.type';
import { ReportReq } from 'types/report.type';
import { CreateOtherTransactionType, CreateTransactionType } from 'types/transaction.type';
import { getBranchId } from 'utils/common';

export const getListTransactionInContract = (payload: CommonReq & PagingReq) => {
  const { bookingId, page, limit } = payload;

  return centralGW.get(`api/v1/user/booking/${bookingId}/transactions`, {
    params: {
      page,
      limit,
    },
    isDisableToast: true,
  });
};

export const getListTransactionInBuilding = (payload: CommonReq & PagingReq & ReportReq) => {
  const {
    buildingId,
    page,
    limit,
    floorId,
    roomId,
    status,
    q,
    startDate,
    endDate,
    paymentMethodId,
    isFull,
  } = payload;

  return centralGW.get(`/api/v1/user/building/${buildingId}/transactions`, {
    params: {
      ...(isFull ? { isFull } : { page, limit }),
      ...(q ? { q } : {}),
      ...(roomId ? { roomId } : {}),
      ...(floorId ? { floorId } : {}),
      ...(status ? { status: status } : {}),
      ...(startDate ? { startDate: startDate } : {}),
      ...(endDate ? { endDate: endDate } : {}),
      ...(paymentMethodId ? { paymentMethodId: paymentMethodId } : {}),
    },
    isDisableToast: true,
  });
};

export const getOverviewTransactionInBuilding = (payload: CommonReq & ReportReq) => {
  const { buildingId, startDate, endDate } = payload;

  return centralGW.get(`/api/v1/user/building/${buildingId}/transactions/overview`, {
    params: {
      startDate,
      endDate,
    },
    isDisableToast: true,
  });
};

export const getOverviewDepositTransactionInBuilding = (payload: CommonReq & ReportReq) => {
  const { buildingId, startDate, endDate } = payload;

  return centralGW.get(`/api/v1/user/building/${buildingId}/deposit-transactions/overview`, {
    params: {
      startDate,
      endDate,
    },
    isDisableToast: true,
  });
};

export const getOverviewOtherTransactionInBuilding = (payload: CommonReq & ReportReq) => {
  const { buildingId, startDate, endDate } = payload;

  return centralGW.get(`/api/v1/property/building/${buildingId}/other-transactions/overview`, {
    params: {
      startDate,
      endDate,
    },
    isDisableToast: true,
  });
};

export const getOverviewDebtTransactionInBuilding = (payload: CommonReq & ReportReq) => {
  const { buildingId, startDate, endDate } = payload;

  return centralGW.get(`/api/v1/property/building/${buildingId}/debits/overview`, {
    params: {
      startDate,
      endDate,
    },
    isDisableToast: true,
  });
};

export const getDepositTransactionInBooking = (payload: CommonReq) => {
  const { bookingId } = payload;

  return centralGW.get(`/api/v1/user/booking/${bookingId}/deposit-transactions`, {
    isDisableToast: true,
  });
};

export const getListTransactionByClientPaging = (payload: CommonReq & PagingReq) => {
  const { userId, page, limit } = payload;

  return centralGW.get(`/api/v1/user/${userId}/transactions`, {
    params: {
      page,
      limit,
    },
    isDisableToast: true,
  });
};

export const createTransaction = (payload: CommonReq & CreateTransactionType) => {
  const { paidUserId, invoiceId, transactionAmount, transactionTypeId, paymentMethodId, isRefund } =
    payload;

  return centralGW.post(`/api/v1/user/${paidUserId}/invoice/${invoiceId}/transaction`, {
    transactionAmount,
    transactionTypeId,
    paymentMethodId,
    isRefund,
  });
};

export const createDepositTransaction = (payload: CommonReq & CreateTransactionType) => {
  const { paidUserId, depositInvoiceId, transactionAmount, paymentMethodId } = payload;

  return centralGW.post(
    `/api/v1/user/${paidUserId}/deposit-invoice/${depositInvoiceId}/transaction`,
    {
      transactionAmount,
      paymentMethodId,
    },
  );
};

export const createOtherTransaction = (payload: CommonReq & CreateOtherTransactionType) => {
  const { buildingId, paymentMethodId, transactionAmount, note, isIncome, imageUrls } = payload;
  const branchId = getBranchId();

  return centralGW.post(`/api/v1/property/branch/${branchId}/building/${buildingId}/transaction`, {
    paymentMethodId,
    transactionAmount,
    note,
    isIncome,
    ...(imageUrls ? { imageUrls } : {}),
  });
};

export const refundDepositTransaction = (payload: CommonReq & CreateTransactionType) => {
  const { paidUserId, depositInvoiceId, transactionAmount, paymentMethodId } = payload;

  return centralGW.post(
    `/api/v1/user/${paidUserId}/deposit-invoice/${depositInvoiceId}/transaction/refund`,
    {
      transactionAmount,
      paymentMethodId,
    },
  );
};

export const deleteTransaction = (payload: CommonReq & CreateTransactionType) => {
  const { transactionId } = payload;

  return centralGW.delete(`/api/v1/user/transaction/${transactionId}`);
};
export const deleteDepositTransaction = (payload: CommonReq) => {
  const { depositTransactionId } = payload;

  return centralGW.delete(`/api/v1/user/deposit-transaction/${depositTransactionId}`);
};

export const getDepositTransactionInBuilding = (payload: CommonReq & PagingReq & ReportReq) => {
  const {
    buildingId,
    page,
    limit,
    floorId,
    roomId,
    status,

    startDate,
    endDate,
    paymentMethodId,
  } = payload;

  return centralGW.get(`/api/v1/user/building/${buildingId}/deposit-transactions`, {
    params: {
      page,
      limit,

      ...(roomId ? { roomId } : {}),
      ...(floorId ? { floorId } : {}),
      ...(status ? { status: status } : {}),
      ...(startDate ? { startDate: startDate } : {}),
      ...(endDate ? { endDate: endDate } : {}),
      ...(paymentMethodId ? { paymentMethodId: paymentMethodId } : {}),
    },
    isDisableToast: true,
  });
};

export const getDebtTransactionInBuilding = (payload: CommonReq & PagingReq & ReportReq) => {
  const { buildingId, page, limit, startDate, endDate } = payload;

  return centralGW.get(`/api/v1/property/building/${buildingId}/debits`, {
    params: {
      page,
      limit,
      ...(startDate ? { startDate: startDate } : {}),
      ...(endDate ? { endDate: endDate } : {}),
    },
    isDisableToast: true,
  });
};
