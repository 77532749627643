import { createAsyncThunk } from '@reduxjs/toolkit';
import { PERMISSION } from 'constants/permission';
import { getBranchDetail, getBranchOverview, getAllBranches, getBranchConfig } from 'services/property';
import { CommonReq } from 'types/common.type';
import { isAllow } from 'utils/common';

export const getBranchAction = createAsyncThunk(
  'property/getBranchAction',
  async (payload: CommonReq) => {
    const response = await getAllBranches(payload);

    return response.data;
  },
);

export const getBranchDetailAction = createAsyncThunk(
  'property/getBranchDetailAction',
  async () => {
    const response = await getBranchDetail();

    return response.data;
  },
);

export const getBranchOverviewAction = createAsyncThunk(
  'property/getBranchOverviewAction',
  async () => {
    if (isAllow([PERMISSION.SB_MANAGEMENT])) {
      const response = await getBranchOverview();

      return response.data;
    }
  },
);

export const getBranchConfigAction = createAsyncThunk(
  'property/getBranchConfigAction',
  async (domain: string) => {
    const response = await getBranchConfig(domain);

    return response.data;
  },
);