import { ROUTES } from 'constants/router';
import { getProfile } from 'services/auth.service';
import { CustomResponseType } from 'types/common.type';
import { LocalStorage } from 'utils/localStorage';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { loginAction } from './actions';
import { unRegisterNotification } from 'services/notify.service';

interface AuthState {
  isAuthenticated: boolean;
  selectedBranch?: string | number;
  data: any;
}

const storage = new LocalStorage();

const initialState: AuthState = {
  isAuthenticated: false,
  selectedBranch: undefined,
  data: [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      unRegisterNotification(storage.getStorageItem(storage.availableKey.NOTIFY_TOKEN)).finally(
        () => {
          storage.clearStorageItem(storage.availableKey.ACCESS_TOKEN);
          storage.clearStorageItem(storage.availableKey.REFRESH_TOKEN);
          storage.clearStorageItem(storage.availableKey.ACCOUNT_INFO);
          storage.clearStorageItem(storage.availableKey.BUILDING_ID);
          storage.clearStorageItem(storage.availableKey.NOTIFY_TOKEN);
          storage.clearStorageItem(storage.availableKey.BRANCH_ID);
          window.location.href = ROUTES.AUTH.LOGIN;
        },
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAction.fulfilled, (_state, action: PayloadAction<CustomResponseType>) => {
      storage.setStorageItem(storage.availableKey.ACCESS_TOKEN, action.payload.data.accessToken);
      storage.setStorageItem(storage.availableKey.REFRESH_TOKEN, action.payload.data.refreshToken);
      getProfile().then((res) => {
        if (res.status === 200) {
          const info = res.data;
          storage.setStorageItem(storage.availableKey.ACCOUNT_INFO, info.data);
        }
      });
    });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
