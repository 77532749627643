import { PasswordType } from 'types/password.type';
import { centralGW } from './axios.service';
import { LoginReq } from 'types/auth.type';
import { CommonReq } from 'types/common.type';

export const login = async (payload: LoginReq) => {
  return centralGW.post('/api/v1/auth/login', payload);
};

export const getProfile = async () => {
  return centralGW.get('/api/v1/auth/profile', { isDisableToast: true });
};

export const changePassword = (payload: PasswordType & CommonReq) => {
  const { password, oldPassword, repeatPassword } = payload;

  return centralGW.post(`/api/v1/auth/account/change-password`, {
    password,
    oldPassword,
    repeatPassword,
  });
};

export const sendOtpForgotPassword = async (payload: { phoneNumber: string }) => {
  return centralGW.post('/api/v1/auth/change-password/otp/send', payload);
};

export const verifyOtpAndChangePassword = async (payload: {
  phoneNumber: string;
  code: string;
  password: string;
  repeatPassword: string;
}) => {
  return centralGW.put('/api/v1/auth/change-password/otp', payload);
};
