import { ReportReq } from 'types/report.type';
import { centralGW } from './axios.service';
import { CommonReq, PagingReq } from 'types/common.type';

export const getAccessLogs = async (payload: CommonReq & PagingReq & ReportReq) => {
  const { buildingId, page, limit, startDate, endDate } = payload;

  return centralGW.get(`/api/v1/account/building/${buildingId}/access-logs`, {
    params: {
      page,
      limit,
      ...(startDate ? { startDate: startDate } : {}),
      ...(endDate ? { endDate: endDate } : {}),
    },
  });
};
